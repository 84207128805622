export default queryString => {
  let params = {}
  queryString &&
    queryString
      .replace(/^\?/, "")
      .split("&")
      .forEach(param => {
        const [k, v] = param.split("=")
        params[decodeURI(k)] = decodeURI(v)
      })

  return params
}
