import * as R from "ramda"
import urlQueryToObject from "./urlQueryToObject"
import urlReplaceNoridcChars, {
  includesNordicChars,
} from "./urlReplaceNoridcChars"

//import urlSearchBaseTypes from '../data/url-search-base-types.json'

/*
 This util handles how filters for search are stored/retrieved from the page URLs
*/

export const getFiltersFromURL = location => {
  // First parse the query params and set the filters parameter
  const queryParams = urlQueryToObject(location.search)

  /* If a f=true (or basically is f= is defined), 
    then filters have already been set (and the user has submitted a search form).

    If it doesn't existing, then the user has landed to a specific page or followed a site link
    */
  const useExistingFilters = R.pathOr(false, ["f"], queryParams)

  // Next get components from path (if existing)
  const [pathType, pathCity, pathArea] = location.pathname
    ? decodeURI(location.pathname.replace(/^\//, "")).split("/")
    : []

  /* URLs should no contain nordic characters. 
    - With SSR pages for search result pages, each page has 
    the strings containing Nordic characters for typecity/area in 
    the page context.
    - With Dynamic search result pages, the Nordic characters need to be stripped 
    from the URL & placed as query parameters so that URLs are clean but pages still understand 
    where Nordic characters should go.

    For these reasons, finally check the query params for type/city/area and overwrite the parsed 
    URL path with the query values (if defined).
    */
  const urlPathContext = {
    type: queryParams.type || pathType,
    city: queryParams.city || pathCity,
    area: queryParams.area || pathArea,
  }
  return { urlPathContext, useExistingFilters, queryParams }
}

export const generateURLFromFilters = filters => {
  /*
        Generate a URL based on the current filters.

        if more than one "type" selected, use toimitilat in the path (else use the specific "type")

        if 0 or then more that 1 cities defined in locations array, fall back to using only the "type" in the url
        if only 1 "city" defined, add "city" to the url path

        if 0 or then more that 1 "areas" defined in locations array, fall back to using only the "type"/"city" in the url
        if only 1 "area" defined, add "area" to the url path
    */
  const types = R.pathOr([], ["types"], filters)
  const cities = []
  const areas = []

  R.pathOr([], ["locations"], filters).map(location => {
    cities.push(location.city)
    areas.push(location.area)
  })

  const uniqCities = R.uniq(cities)
  const uniqAreas = R.uniq(areas)

  /* 
        The basic usecases are relatively straight forward to handle, e.g.

        Case 1:
            filters: [
                {city: "helsinki", area: "kalasatama"}
            ]
            The page url needs to be /type/helsinki/kalasatama (only one unique city, one unique area)

        Case 2:
            filters: [
                {city: "helsinki", area: "sörnäinen"},
                {city: "helsinki", area: "kalasatama"}
            ]
            The page url needs to be /type/helsinki (only one unique city, two unique areas)

        To handle the following use case correctly, the logic needs expanded slightly:
            
        Case 3:
            filters: [
                {city: "helsinki"},
                {city: "helsinki", area: "kalasatama"}
            ]

        Here the results will need to show the results that are in "Helsinki" AND in "Kalasatama, Helsinki".
        The page URL should be for /type/helsinki

        Even though there is only one unique "city" and one unique "area", the "city" appears in multiple search 
        terms so the city level should be used for the page URL.

        There are other cases that will not be handled by this logic, e.g. 
        
        Case 4:
            filters: [
                {city: "helsinki", area: "kalasatama", streetAddress: "katu 123"}
                {city: "helsinki", area: "kalasatama", streetAddress: "tie 456"}
            ]
        

        Here the logic will still use the URL /type/helsinki even the /type/helsinki/kalasatama would be valid. 
        If this is a problem, the logic below can be expanded. But really, it will be a small % of searches focusing on the address level and 
        the impact is very small (wrong URL and content).
        
    */

  const pathType = types.length === 1 ? types[0] : "toimitilat"
  const pathCity = uniqCities.length === 1 ? uniqCities[0] : null
  const pathArea =
    uniqCities.length === cities.length && uniqAreas.length === 1
      ? areas[0]
      : null

  // The path of URL needs to have nordic characters removed to work with the site
  const path = urlReplaceNoridcChars(
    pathCity && pathArea
      ? `/${pathType}/${pathCity}/${pathArea}`
      : pathCity
      ? `/${pathType}/${pathCity}`
      : `/${pathType}`
  )
  let queryParams = "f=true"
  // Add sizes to query string (support for chatbot link) - this done bythe Chatbot
  queryParams = (filters.sizeMin && filters.sizeMin !== "") ? `${queryParams}&smin=${filters.sizeMin}`: queryParams;
  queryParams = (filters.sizeMax && filters.sizeMax !== "") ? `${queryParams}&smax=${filters.sizeMax}`: queryParams;
  //queryParams = (R.path(['workspaces','min'],filters)) ? `${queryParams}&wmin=${filters.workspaces.min}`: queryParams;
  //queryParams = (R.path(['workspaces','max'],filters)) ? `${queryParams}&wmax=${filters.workspaces.max}`: queryParams;

  // Make sure to add the extra query params that specify the correct strings
  // that contain the nordic characters (these are required for dynamic pages)
  queryParams =
    pathCity && includesNordicChars(pathCity)
      ? `${queryParams}&city=${pathCity}`
      : queryParams
  queryParams =
    pathArea && includesNordicChars(pathArea)
      ? `${queryParams}&area=${pathArea}`
      : queryParams

  return `${path}?${queryParams}`
}
